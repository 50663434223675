//Valida E-mail
function IsEmail(email) {
    var exclude = /[^@\-\.\w]|^[_@\.\-]|[\._\-]{2}|[@\.]{2}|(@)[^@]*\1/;
    var check = /@[\w\-]+\./;
    var checkend = /\.[a-zA-Z]{2,3}$/;
    if (
        email.search(exclude) != -1 ||
        email.search(check) == -1 ||
        email.search(checkend) == -1
    ) {
        return false;
    } else {
        return true;
    }
}

$('select').selectric();

//Envia E-mail Contato
$("#formContato").submit(function(e) {
    e.preventDefault();

    $("#btnEnviarContato").html(
        'Aguarde. Enviando.'
    );

    var nome = $("#nome").val();
    var email = $("#email").val();
    var telefone = $("#telefone").val();
    var mensagem = $("#mensagem").val();
    var erros = 0;

    if (nome == "") {
        $("#nome").addClass("errorField");
        $(".erroNome").addClass("showMsg");
        erros++;
    } else {
        $("#nome").removeClass("errorField");
        $(".erroNome").removeClass("showMsg");
    }
    if (email == "" || !IsEmail(email)) {
        $("#email").addClass("errorField");
        $(".erroEmail").addClass("showMsg");
        erros++;
    } else {
        $("#email").removeClass("errorField");
        $(".erroEmail").removeClass("showMsg");
    }
    if (telefone == "") {
        $("#telefone").addClass("errorField");
        $(".erroTelefone").addClass("showMsg");
        erros++;
    } else {
        $("#telefone").removeClass("errorField");
        $(".erroTelefone").removeClass("showMsg");
    }
    if (mensagem == "") {
        $("#mensagem").addClass("errorField");
        $(".erroMensagem").addClass("showMsg");
        erros++;
    } else {
        $("#mensagem").removeClass("errorField");
        $(".erroMensagem").removeClass("showMsg");
    }

    if (erros == 0) {
        $.post(
            url + "/email/form_contato.php",
            $("#formContato").serialize(),
            function(data) {
                if (data == "1") {
                    $(".erroContato").fadeOut("slow");
                    $("#formContato")[0].reset();
                    $(".sucessoContato").fadeIn("slow");
                    $("#btnEnviarContato").html("Enviar");
                }
            }
        );
    } else {
        $(".erroContato").fadeIn("slow");
        $("#btnEnviarContato").html("Enviar Mensagem <span></span>");
    }
});

$('.trabalhe').on('click', function(){
    $('.formularioTrabalhe').fadeIn();
});

//Envia E-mail Contato
$("#formTrabalhe").submit(function(e) {
    e.preventDefault();

    $("#btnEnviarTrabalhe").html(
        'Aguarde. Enviando.'
    );

    var nome = $("#nomeTrabalhe").val();
    var email = $("#emailTrabalhe").val();
    var erros = 0;

    if (nome == "") {
        $("#nome").addClass("errorField");
        $(".erroNome").addClass("showMsg");
        erros++;
    } else {
        $("#nome").removeClass("errorField");
        $(".erroNome").removeClass("showMsg");
    }
    if (email == "" || !IsEmail(email)) {
        $("#emailTrabalhe").addClass("errorField");
        $(".erroEmail").addClass("showMsg");
        erros++;
    } else {
        $("#emailTrabalhe").removeClass("errorField");
        $(".erroEmail").removeClass("showMsg");
    }

    if (erros == 0) {

        var formData = new FormData(this);

        $.ajax({
            url: url + "/email/form_trabalhe.php",
            type: 'POST',
            data: formData,
            success: function(data) {
                $(".erroTrabalhe").fadeOut("slow");
                $("#formTrabalhe")[0].reset();
                $(".sucessoTrabalhe").fadeIn("slow");
                $("#btnEnviarTrabalhe").html("Enviar");
            },
            cache: false,
            contentType: false,
            processData: false,
            xhr: function() { // Custom XMLHttpRequest
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) { // Avalia se tem suporte a propriedade upload
                    myXhr.upload.addEventListener('progress', function() {
                        /* faz alguma coisa durante o progresso do upload */
                    }, false);
                }
                return myXhr;
            }
        });

        // $.post(
        //     url + "/email/form_contato.php",
        //     $("#formContato").serialize(),
        //     function(data) {
        //         if (data == "1") {
        //             $(".erroContato").fadeOut("slow");
        //             $("#formContato")[0].reset();
        //             $(".sucessoContato").fadeIn("slow");
        //             $("#btnEnviarContato").html("Enviar");
        //         }
        //     }
        // );
    } else {
        $(".erroTrabalhe").fadeIn("slow");
        $("#btnEnviarTrabalhe").html("Enviar Mensagem <span></span>");
    }
});