$(".carousel").owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    items: 1,
    autoplay: true,
    autoplayTimeout: 3000,
    animateOut: 'fadeOut'
});

// $('.btn-down').on('click', function(){
//     $('html, body').animate({
//         scrollTop: $('#quem-somos').offset().top - 60
//     }, 1000);
// });