
$('#rollLinks').localScroll({
    target:'body',
    // offset: -100
});

$('#subrollLinks').localScroll({
    target:'body',
    // offset: -100
});

$('#subrollLinks2').localScroll({
    target:'body',
    // offset: -100
});

$('.botaoResponsivo').on('click', function(){
    $("header").toggleClass('blackMobile');
    $('.menu nav').toggleClass('ativo');
    $('.linhasmr').toggleClass('ativo');
    if ($('.linhasmr').hasClass('ativo')) {
        $(".linhasmr").addClass("ativo");
    } else {
        $(".linhasmr").removeClass("ativo");
    }
    return false;
});



$('.menu nav a').on('click', function(){
    $('.menu nav').removeClass('ativo');
    $('.linhasmr').removeClass('ativo');
});