$(".carouselGaleria").owlCarousel({
    autoHeight:true,
    loop: false,
    nav: true,
    dots: false,
    items: 1,
    autoplay: false,
    lazyLoad: true,
    info: true
});

// $('.btn-down').on('click', function(){
//     $('html, body').animate({
//         scrollTop: $('#quem-somos').offset().top - 60
//     }, 1000);
// });