window.sr = new ScrollReveal({
    reset: false,
    mobile: true
});
var fromtop = {
    origin: "top",
    distance: "50%",
    duration: 1500,
    scale: 0,
    delay: 500
};
var fromleft = {
    origin: "left",
    distance: "50%",
    duration: 1500,
    scale: 0,
    opacity: 0,
    delay: 500
};
var fromleftTitle = {
    origin: "left",
    distance: "100%",
    duration: 1500,
    scale: 0,
    opacity: 0,
    delay: 500
};
var frombottom = {
    origin: "bottom",
    distance: "70%",
    duration: 2000,
    scale: 0,
    opacity: 0,
    delay: 500
};
var frombottomTitle = {
    origin: "bottom",
    distance: "70%",
    duration: 2000,
    scale: 0,
    opacity: 0,
    delay: 500
};
var fromright = {
    origin: "right",
    distance: "50%",
    duration: 1500,
    scale: 0,
    opacity: 0,
    delay: 500
};
var fromrightTitle = {
    origin: "right",
    distance: "100%",
    duration: 1500,
    scale: 0,
    opacity: 0,
    delay: 500
};

/************************* HOME */

//Quem Somos
sr.reveal('.proxima-etapa img', { origin: "left", distance: "50%", duration: 1500, scale: 0, opacity: 0, delay: 500 }, 50);
sr.reveal('.proxima-etapa h1', { origin: "bottom", distance: "50%", duration: 1500, scale: 0, opacity: 0, delay: 500 }, 50);
sr.reveal('.proxima-etapa h2', { origin: "bottom", distance: "50%", duration: 1500, scale: 0, opacity: 0, delay: 500 }, 50);
sr.reveal('.proxima-etapa .infos', { origin: "bottom", distance: "50%", duration: 1500, scale: 0, opacity: 0, delay: 500 }, 50);


sr.reveal('.ultima-etapa h1', { origin: "left", distance: "50%", duration: 1500, scale: 0, opacity: 0, delay: 500 }, 50);
sr.reveal('.ultima-etapa h2', { origin: "left", distance: "50%", duration: 1500, scale: 0, opacity: 0, delay: 600 }, 50);
sr.reveal('.ultima-etapa h2', { origin: "left", distance: "50%", duration: 1500, scale: 0, opacity: 0, delay: 600 }, 50);
sr.reveal('.ultima-etapa .classificacao .nome', { origin: "bottom", distance: "50%", duration: 1500, scale: 0, opacity: 0, delay: 600 }, 50);

sr.reveal('.galeria h1', { origin: "left", distance: "50%", duration: 1500, scale: 0, opacity: 0, delay: 500 }, 50);
sr.reveal('.galeria a', { origin: "bottom", distance: "50%", duration: 1500, scale: 0, opacity: 0, delay: 500 }, 50);

sr.reveal('.fullTitle h1', { origin: "left", distance: "50%", duration: 1500, scale: 0, opacity: 0, delay: 500 }, 50);